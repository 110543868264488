import React from "react"
import { graphql } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "styled-components"

// Utils
import { formatDataForBlogPostPreview } from "../utils/dataHelpers"

// Styles
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import BlogPostMetaData from "../components/BlogPostMetaData"
import BlogPostPreview from "../components/BlogPostPreview"

// Types
import { IBlogPostPage } from "../prismic-types/templates/blog-post-page"
import {
  IBlogPostPreview,
  IBlogPostPreviewRawData,
} from "../prismic-types/blog-page"
import { IDemoPage } from "../prismic-types/request-demo-page"

interface IProps {
  content: IBlogPostPage
  otherBlogPostPreviews: IBlogPostPreview[]
  demoPage: IDemoPage
}

const BlogPost: React.FC<IProps> = ({
  content,
  otherBlogPostPreviews,
  demoPage,
}) => {
  const imageUrl = content.image.url
  const author = content.blogPostMetaData.author
  const publishedData = content.blogPostMetaData.publishDate
  const title = content.meta.title || content.title
  const slug = content.slug
  const lang = content.meta.lang

  return (
    <>
      <Seo
        title={title}
        description={content.meta.description}
        imageUrl={imageUrl}
        datePublished={publishedData}
        dateModified={publishedData}
        article={{
          author: [{ name: author }],
          images: [imageUrl],
          title,
        }}
        slug={`blog/${slug}`}
        lang={lang}
        breadCrumbItems={[
          { name: "Blogs", url: "blog" },
          { name: title, url: `blog/${slug}` },
        ]}
      />
      <StyledContentWrapper>
        <Subtitle>{content.subtitle}</Subtitle>
        <Title>{content.title}</Title>
        <PostWrapper>
          <StyledBlogPostMetaData {...content.blogPostMetaData} />
          <div>
            {content.paragraphOne && (
              <Paragraph
                dangerouslySetInnerHTML={{ __html: content.paragraphOne }}
              />
            )}
            {content.image.url && (
              <StyledGatsbyImage
                fluid={content.image.fluid}
                alt={content.image.alt || ""}
              />
            )}
            {content.paragraphTwo && (
              <Paragraph
                dangerouslySetInnerHTML={{ __html: content.paragraphTwo }}
              />
            )}
            {content.imageOptional.url && (
              <StyledGatsbyImage
                fluid={content.imageOptional.url}
                alt={content.imageOptional.alt || ""}
              />
            )}
            {content.paragraphOptional && (
              <Paragraph
                dangerouslySetInnerHTML={{ __html: content.paragraphOptional }}
              />
            )}
          </div>
        </PostWrapper>
        {otherBlogPostPreviews.length > 0 && (
          <>
            <ReadMoreTitle>Lees meer blogs</ReadMoreTitle>
            {otherBlogPostPreviews.map((blogPostPreview) => (
              <BlogPostPreview
                key={blogPostPreview.url}
                demoPage={demoPage}
                {...blogPostPreview}
              />
            ))}
          </>
        )}
      </StyledContentWrapper>
    </>
  )
}

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: 100px;

  ${mq.from.S`
    padding-top: 160px;
  `}

  ${mq.from.L`
    padding-top: 200px;
    max-width: calc(10/12*100%);
  `}
`

const Subtitle = styled.h4`
  ${textStyles.subtitle}
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${colors.main["100"]};

  ${mq.from.M`
    margin-bottom: 8px;
    font-size: 16px !important;
  `}
`

const Title = styled.h1`
  color: ${colors.purpleLight};
  ${textStyles.titleL}
  margin-bottom: 32px;

  ${mq.from.M`
    margin-bottom: 48px;
  `}
`

const PostWrapper = styled.div`
  margin-bottom: 64px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${colors.whitePurple};

  ${mq.from.M`
    flex-wrap: nowrap;
    display:flex;
    margin-bottom: 100px;
    padding: 0;
    border: none;
  `}
`

const StyledBlogPostMetaData = styled(BlogPostMetaData)`
  margin-bottom: 24px;

  ${mq.from.M`
    flex-shrink: 0;
    margin-right: 64px;
    width: 17%;
  `}
`

const Paragraph = styled.div`
  color: ${colors.main["100"]};
  ${textStyles.body}

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h3 {
    ${textStyles.titleS};
    margin-bottom: 8px;
  }

  > ul,
  > ol {
    padding-left: 1em;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${mq.from.S`
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  `}
`

const StyledGatsbyImage = styled(GatsbyImage)<{
  fluid: FluidObject | FluidObject[]
}>`
  margin-bottom: 24px;

  ${mq.from.S`
    margin-bottom: 40px;
  `}
`

const ReadMoreTitle = styled.h2`
  color: ${colors.main["200"]};
  ${textStyles.titleM}
  margin-bottom: 32px;

  ${mq.from.S`
    margin-bottom: 48px;
  `}
`

export const query = graphql`
  query blogPostPage($uid: String!, $locale: String!) {
    prismicBlogPostPage(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      lang
      last_publication_date
      data {
        meta_title
        meta_description
        subtitle {
          text
        }
        title {
          text
        }
        author_avatar {
          url
          alt
        }
        author
        publish_date
        paragraph_1 {
          html
        }
        image {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          alt
          url
        }
        paragraph_2 {
          html
        }
        image_optional {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          alt
        }
        paragraph_optional {
          html
        }
      }
    }

    allPrismicBlogPostPage(filter: { uid: { ne: $uid } }, limit: 3) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            author_avatar {
              url
              alt
            }
            author
            publish_date
            post_summary {
              html
            }
            image {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
              alt
            }
          }
        }
      }
    }

    prismicRequestADemoPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        text {
          html
        }
        download_title {
          text
        }
        download_text {
          html
        }
        name
        notes
        phone
        email
        company
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    uid,
    lang,
    last_publication_date,
    first_publication_date,
  } = data.prismicBlogPostPage
  const rawBlogPostsData: IBlogPostPreviewRawData = data.allPrismicBlogPostPage
  const { data: demoPageData } = data.prismicRequestADemoPage

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    lang,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
  }

  const subtitle = raw.subtitle.text
  const title = raw.title.text

  const blogPostMetaData = {
    authorAvatar: raw.author_avatar,
    author: raw.author,
    publishDate: raw.publish_date,
  }

  const paragraphOne = raw.paragraph_1.html
  const paragraphTwo = raw.paragraph_2.html
  const paragraphOptional = raw.paragraph_optional.html
  const image = {
    fluid: raw.image.fluid,
    url: raw.image.url,
    alt: raw.image.alt,
  }
  const imageOptional = {
    url: raw.image_optional.fluid,
    alt: raw.image_optional.alt,
  }

  const otherBlogPostPreviews = formatDataForBlogPostPreview(rawBlogPostsData)

  const content: IBlogPostPage = {
    meta,
    subtitle,
    title,
    blogPostMetaData,
    paragraphOne,
    paragraphTwo,
    paragraphOptional,
    image,
    imageOptional,
    slug: uid,
  }

  const demoPage: IDemoPage = {
    text: demoPageData.text.html,
    title: demoPageData.title.text,
    downloadText: demoPageData.download_text.html,
    downloadTitle: demoPageData.download_title.text,
    labels: {
      name: demoPageData.name,
      company: demoPageData.company,
      email: demoPageData.email,
      notes: demoPageData.notes,
      phone: demoPageData.phone,
      interest: demoPageData.interest,
      employees: demoPageData.employees,
    },
  }

  return (
    <BlogPost
      content={content}
      otherBlogPostPreviews={otherBlogPostPreviews}
      demoPage={demoPage}
    />
  )
}
